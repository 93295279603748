import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/'
import { 
    InputField
} from '../../components/library/entitysync'; 


function inputText() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <InputField 
                    field="input_text_basic" 
                    label="Default" 
                />
                <hr />
                <InputField
                    required
                    field="input_text_required"
                    label="Required"
                />
                <hr />
                <InputField
                    field="input_text_password"
                    label="Password"
                    type="password"
                />
                <hr />
                <InputField
                    field="input_text_readonly"
                    label="Read Only"
                    readOnly
                />
                <hr />
                <InputField
                    field="input_number"
                    label="Number"
                    type="number"
                />
                <hr />
                <InputField
                    field="input_helper"
                    label="With Helper Text"
                    helperText="Something about this field"
                />
                <hr />
                <InputField
                    field="input_error"
                    label="With Error"
                    helperText="Something wrong"
                    error
                />
                <hr />
                <InputField
                    field="input_small"
                    label="Small input"
                    small
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputText
